<template>
  <Layout tituloPagina="Sistema | Ajustes | Plantilla de notificación por correo">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          <span class="d-none d-sm-block">
            Ajustes en plantillas de notificación por correo
          </span>
          <span class="d-block d-sm-none">
            Plantillas de notificación
          </span>
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="actualizar()"
                  v-if="!bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  Actualizar
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  Actualizar
                </span>
              </li>
              <li>
                <a class="dropdown-item" href="#" onclick="return false;" @click="$router.go(-1)">
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <label>Enviar notificación de servicio por vencer</label>
            <select
              class="form-select"
              v-model="ajustes.enviar_notificacion_servicio_por_vencer"
              @change="generarPlantillas()"
            >
              <option value="0">No</option>
              <option value="1">Si</option>
            </select>
          </div>

          <div v-if="ajustes.enviar_notificacion_servicio_por_vencer == true" class="col-md-3">
            <label>Emisor de notificaciones</label>
            <div class="input-group">
              <input
                type="email"
                class="valida form-control text-right"
                v-model="ajustes.emisor_notificacion"
                placeholder="no-reply@argus.black"
              />
              <span class="input-group-text">
                <i class="mdi mdi-email"></i>
              </span>
            </div>
          </div>

          <div v-if="ajustes.enviar_notificacion_servicio_por_vencer == true" class="col-md-3">
            <label>Día de notificación de servicio por vencer</label>
            <div class="input-group">
              <input
                class="valida form-control text-right"
                v-model="ajustes.dias_enviar_notificacion_servicio_por_vencer"
                placeholder="#"
              />
              <span class="input-group-text">
                dias
              </span>
            </div>
          </div>

          <div v-if="ajustes.enviar_notificacion_servicio_por_vencer == true" class="col-md-3">
            <label>Hora de envío</label>
            <div class="input-group">
              <input
                type="time"
                class="horaCorte form-control text-right"
                v-model="ajustes.hora_envio_notificacion"
                placeholder="00:00"
              />
              <span class="input-group-text">Hrs.</span>
            </div>
          </div>
        </div>
        <br>
        <div class="text-right" v-if="ajustes.enviar_notificacion_servicio_por_vencer == true">
          <button class="btn btn-sm btn-success text-right" @click="actualizar()" :disabled="bandera_spinner">
            <i class="mdi" :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"></i>
            Actualizar
          </button>
        </div>
      </div>
    </div>

    <div class="card" v-show="ajustes.enviar_notificacion_servicio_por_vencer == true">
      <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active" role="tab"
            data-bs-toggle="tab" href="#facturaGenerada"
          >
            Formato de notificación para factura generada
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link" role="tab"
            data-bs-toggle="tab" href="#servicioPorVencer"
          >
            Formato de notificación de servicio por vencer
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link" role="tab"
            data-bs-toggle="tab" href="#servicioVencido"
          >
            Formato de notificación de servicio vencido
          </a>
        </li>
      </ul>
      <div class="tab-content p-4">
        <div class="tab-pane active" id="facturaGenerada" role="tabpanel">
          <div class="row">
            <div class="col-md-6">
              <label>Agregar un asunto de factura generada</label>
              <input
                type="text"
                placeholder="Asunto"
                v-model="ajustes.asunto_factura"
                class="form-control"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 align-items-center mt-3">
              <h6>
                Plantilla del mensaje
                <small class="font-size-12">
                  Variables Dinamicas: $saldo$, $fechaPago$, $fechaLimite$,
                  $cliente$, $empresa$
                </small>
              </h6>
            </div>
            <div class="col-md-6 text-right">
              <button
                type="button"
                class="btn btn-secondary btn-sm me-2"
                @click="generarPlantillaFactura()"
              >
                Generar plantilla
              </button>
              <button 
                class="btn btn-sm btn-success text-right"
                @click="actualizar()"
                :disabled="bandera_spinner"
              >
                <i class="mdi" :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"></i>
                Actualizar
              </button>
            </div>
          </div>

          <textarea id="plantilla_factura" style="display: none;"></textarea>
        </div>
        <div class="tab-pane" id="servicioPorVencer" role="tabpanel">
          <div class="row">
            <div class="col-md-6">
              <label>Agregar un asunto por vencer</label>
              <input
                type="text"
                placeholder="Asunto"
                v-model="ajustes.asunto_por_vencer"
                class="form-control"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 align-items-center mt-3">
              <h6>
                Plantilla del mensaje
                <small class="font-size-12">
                  Variables Dinamicas: $saldo$, $fechaPago$, $fechaLimite$,
                  $fechaCorte$, $cliente$, $empresa$
                </small>
              </h6>
            </div>
            <div class="col-md-6 text-right">
              <button
                type="button"
                class="btn btn-secondary btn-sm me-2"
                @click="generarPlantillaPorVecer()"
              >
                Generar plantilla
              </button>
              <button 
                class="btn btn-sm btn-success text-right"
                @click="actualizar()"
                :disabled="bandera_spinner"
              >
                <i class="mdi" :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"></i>
                Actualizar
              </button>
            </div>
          </div>

          <textarea id="plantilla_por_vencer" style="display: none;"></textarea>
        </div>
        <div class="tab-pane" id="servicioVencido" role="tabpanel">
          <div class="row">
            <div class="col-md-6">
              <label>Agregar un asunto de servicio vencido</label>
              <input
                type="text"
                placeholder="Asunto"
                v-model="ajustes.asunto_vencido"
                class="form-control"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-8 align-items-center mt-3">
              <h6>
                Plantilla del mensaje
                <small class="font-size-12">
                  Variables Dinamicas: $saldo$, $fechaPago$, $fechaLimite$,
                  $cargoReconexion$, $cliente$, $empresa$
                </small>
              </h6>
            </div>
            <div class="col-md-4 text-right">
              <button
                type="button"
                class="btn btn-secondary btn-sm me-2"
                @click="generarPlantillaVencida()"
              >
                Generar plantilla
              </button>
              <button 
                class="btn btn-sm btn-success text-right"
                @click="actualizar()"
                :disabled="bandera_spinner"
              >
                <i class="mdi" :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"></i>
                Actualizar
              </button>
            </div>
          </div>

          <textarea id="plantilla_vencida" style="display: none;"></textarea>
        </div>
      </div>
    </div>

    <div class="text-right pb-4">
      <div class="btn-group">
        <button class="btn btn-secondary"
          @click="$router.go(-1)">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button class="btn btn-success" @click="actualizar()" :disabled="bandera_spinner" v-if="ajustes.enviar_notificacion_servicio_por_vencer == false">
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          Actualizar
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import SistemaSrv from '@/services/SistemaSrv.js'
import PlantillaSrv from '@/services/PlantillaSrv.js'
import axios from 'axios'

const $ = require("jquery")
import "summernote/dist/summernote-lite.min.css"
import "summernote/dist/summernote-lite.min"

export default {
  name: 'PlantillaNotificacionCorreo',
  components: {
    Layout
  },
  data() {
    return {
      ajustes: {
        asunto_factura: '',
        asunto_por_vencer: '',
        asunto_vencido: '',
        emisor_notificacion: '',
        enviar_notificacion_servicio_por_vencer: true,
        dias_enviar_notificacion_servicio_por_vencer: 1,
        hora_envio_notificacion: '04:00'
      },
      plantillas: {
        plantilla_factura_generada: '', 
        plantilla_por_vencer: '', 
        plantilla_vencida: ''
      },
      bandera_spinner: false,
      movil: false
    }
  },

  created() {
    let self = this

    if (navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i) ||
        window.innerWidth <= 780
    ) {
      self.movil = true
    }

    self.refrescarAjustes()
    self.refrescarPlantillas()
  },
  methods: {
    actualizar() {
      let self = this,
        ajustes = Object.assign({}, self.ajustes)

      self.bandera_spinner = true

      // Actualización de ajustes
      let prom1 = SistemaSrv.actualizar(ajustes)

      // Actualización de plantillas
      let plantillas = Object.keys(self.plantillas).map( function(nombrePlantilla) {
        return { nombre: nombrePlantilla, contenido: self.plantillas[nombrePlantilla] }
      })
      let prom2 = PlantillaSrv.actualizarPlantillas(plantillas)
      
      axios.all([prom1, prom2]).then(response => {
        iu.msg.success('Datos actualizados')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron actualizar los datos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    generarPlantillaFactura() {
      var self = this
      PlantillaSrv.plantillaPorDefectoJSON('plantilla_factura_generada').then(response => {
        self.plantillas.plantilla_factura_generada = response.data.contenido
        $('#plantilla_factura').summernote('code', self.plantillas.plantilla_factura_generada)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    generarPlantillaPorVecer() {
      var self = this
      PlantillaSrv.plantillaPorDefectoJSON('plantilla_por_vencer').then(response => {
        self.plantillas.plantilla_por_vencer = response.data.contenido
        $('#plantilla_por_vencer').summernote('code', self.plantillas.plantilla_por_vencer)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    generarPlantillaVencida() {
      var self = this
      PlantillaSrv.plantillaPorDefectoJSON('plantilla_vencida').then(response => {
        self.plantillas.plantilla_vencida = response.data.contenido
        $('#plantilla_vencida').summernote('code', self.plantillas.plantilla_vencida)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    generarPlantillas() {
      var self = this
      if (self.plantillas.plantilla_factura_generada == '') {
        self.generarPlantillaFactura()
      }
      if (self.plantillas.plantilla_por_vencer == '') {
        self.generarPlantillaPorVecer()
      }
      if (self.plantillas.plantilla_vencida == '') {
        self.generarPlantillaVencida()
      }
    },

    refrescarAjustes() {
      let self = this

      SistemaSrv.ajustes([
        'asunto_factura',
        'asunto_por_vencer',
        'asunto_vencido',
        'emisor_notificacion',
        'enviar_notificacion_servicio_por_vencer',
        'dias_enviar_notificacion_servicio_por_vencer',
        'hora_envio_notificacion'
      ]).then(response => {
        let ajustes = response.data
        Object.assign(self.ajustes, ajustes)
      })
    },

    refrescarPlantillas() {
      let self = this
      
      PlantillaSrv.plantillasJSON(['plantilla_factura_generada', 'plantilla_por_vencer', 'plantilla_vencida']).then(response => {
        let plantillas = response.data

        plantillas.forEach( plantilla => {
          self.plantillas[plantilla.nombre] = plantilla.contenido
        })

        $('#plantilla_factura').summernote('code',self.plantillas.plantilla_factura_generada)
        $('#plantilla_por_vencer').summernote('code',self.plantillas.plantilla_por_vencer)
        $('#plantilla_vencida').summernote('code', self.plantillas.plantilla_vencida)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    }
  },
  mounted() {
    let self = this
    //es para el summernote de mensaje de correo
    $(document).ready(function() {
      var toolbar = [
        ['style', ['style', 'bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['height', ['height']],
        ['view', ['codeview', 'help']]
      ]

      if (self.movil) {
        toolbar = [
          ['style', ['style', 'bold', 'italic', 'underline']],
          ['fontsize', ['fontsize', 'color']],
          ['para', ['ul', 'paragraph']],
          ['table', ['table', 'height']],
          ['view', ['codeview']]
        ]
      }

      $('#plantilla_factura').summernote({
        height: 680,
        callbacks: {
          onChange: function(contents, $editable) {
            self.plantillas.plantilla_factura_generada = contents
          }
        },
        toolbar: toolbar
      }),
      $('#plantilla_por_vencer').summernote({
        height: 680,
        callbacks: {
          onChange: function(contents, $editable) {
            self.plantillas.plantilla_por_vencer = contents
          }
        },
        toolbar: toolbar
      }),
      $('#plantilla_vencida').summernote({
        height: 680,
        callbacks: {
          onChange: function(contents, $editable) {
            self.plantillas.plantilla_vencida = contents
          }
        },
        toolbar: toolbar
      })
      $('.note-editable').css('background-color', '#FFFFFF')
    })
  }
}
</script>

<style>
</style>